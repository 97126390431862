<template>
  <Header title="Aktivácia" />
  <router-link to="/" class="back-nav-button" v-if="routerLink.indexOf(step) > -1"><i class="bi bi-arrow-left"></i></router-link>
  <a @click="moveStep(false)" class="back-nav-button" v-if="routerLink.indexOf(step) <= -1"><i class="bi bi-arrow-left"></i></a>
  <div id="activation" class="container">
    <div id="how-to" v-if="step === 0">
      <p>Darovanie krvi je skutočným darom života, ktorý zdravý jedinec môže poskytnúť ľuďom chorým a po úrazoch. je to prospešný, bezpečný a jednoduchý humánny krok, ktorý môže zachrániť ľudský život.</p>
      <p>Aplikácia <b>Elektronická karta darcu</b> Vám poskytne základné informácie o darovaní krvi a odberových miestach, pre jej plnohodnotné využitie je však potrebné byť darcom krvi v Národnej transfúznej službe SR.</p>
      <h3>Ako na to?</h3>
      <p>Darujte krv na ktoromkoľvek z pracovísk NTS SR a osobne požiadajte na evidencii darcov krvi o prístup do mobilnej aplikácie. Najskor o 5 minút od požiadania si môžete aplikáciu aktivovať. Po úspešnej aktivácii svojho prístupu do aplikácie získate prehľad o zaujímavých informáciách o Vašej krvi, ktorá pomáha zachraňovať životy.</p>
      <div class="fixed-bottom-button">
        <input type="submit" class="submit btn btn-primary start-button" value="Aktivovať" @click="moveStep(true)"/>
      </div>
    </div>

    <div id="agreement" v-if="step === 1">
      <h3><b><i>Oznámenie o spracúvaní osobných údajov pre použitie v mobilnej aplikácii NTS SR – Elektronická karta darcu</i></b></h3>
      <p>Riadne spracúvanie Vašich osobných údajov ako dotknutých osôb je pre NTS SR dôležité a ich ochrana je úplnou samozrejmosťou. Radi by sme Vám týmto poskytli informácie ohľadom spracúvania Vašich osobných údajov v súvislosti s novým nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a voľnom pohybe týchto údajov, ktorým sa zrušuje smernica 95/46/ES a ktoré kladie nové požiadavky v oblasti ochrany Vašich osobných údajov.</p>
      <p>Prečítajte si, prosím, nižšie uvedené informácie o spracúvaní osobných údajov pre použitie v mobilnej aplikácií NTS SR – Elektronická karta darcu, ktoré sme koncipovali v zjednodušenej forme tak, aby tieto informácie boli pre Vás čo najprehľadnejšie a praktické. Pretože sú informácie o spracúvaní osobných údajov veľmi komplexné a rozsiahle, navštívte, prosím, našu webovú stránku http://www.ntssr.sk/zasadyochranyudajov, kde nájdete podrobnejšie a aktuálne informácie.</p>
      <h4>1. Identifikačné údaje prevádzkovateľa informačného systému</h4>
      <p>Prevádzkovateľom informačného systému je Národná transfúznej služba SR, so sídlom: Ďumbierska 3/L, 831 01 Bratislava, IČO: 30 853 915.</p>
      <h4>2. Účel spracúvania osobných údajov</h4>
      <p>Účelom spracúvania osobných údajov dotknutej osoby zo strany NTS SR je funkčné využívanie mobilnej aplikácie NTS SR – Elektronická karta darcu. Zobrazovanie informácií v mobilnej aplikácií o darcovi krvi a histórií odberov realizovaných v NTS SR od roku 2013.</p>
      <h4>3. Zdroj spracúvaných osobných údajov</h4>
      <p>Zdrojom spracúvaných osobných údajov je informačný systém evidencie darcov krvi.</p>
      <h4>4. Zoznam spracúvaných osobných údajov</h4>
      <p>NTS SR spracúva v informačnom systéme v mobilnej aplikácie NTS SR – Elektronická karta darcu nasledovné osobné údaje poskytnuté dotknutou osobou: evidenčné číslo a číslo mobilného telefónu v šifrovanej podobe, krstné meno, pohlavie, krvná skupina, fenotyp, čísla a typy odberov a krvné obrazy prislúchajúce k týmto odberom.</p>
      <h4>5. Oprávnené osoby</h4>
      <p>Oprávnenou osobu je fyzická osoba, ktorá prichádza do kontaktu s Vašimi osobnými údajmi u prevádzkovateľa NTS SR v rámci plnenia svojich pracovných (služobných) povinností alebo obdobného vzťahu založeného napr. na základe poverenia, vymenovania, zvolenia alebo v rámci výkonu verejnej funkcie, a ktorá vykonáva prevádzkovateľom určené spracovateľské operácie s osobnými údajmi. NTS SR ako prevádzkovateľ v rámci svojej povinnosti poučil všetky oprávnené osoby v rozsahu ich oprávnení, povolených činností a podmienok spracúvania osobných údajov.</p>
      <h4>6. Podmienky spracovania údajov v mobilnej aplikácii NTS SR – Elektronická karta darcu</h4>
      <p>Vaše osobné údaje budú spracovávané v mobilnej aplikácii NTS SR – Elektronická karta darcu až po Vašej výslovnej ústnej žiadosti podanej osobne na evidencii darcov krvi ktoréhokoľvek pracoviska NTS SR a následnom zaregistrovaní mobilného zariadenia do systému prostredníctvom jedinečného evidenčného čísla darcu a overení Vášho telefónneho čísla prostredníctvom aktivačného PIN kódu, ktorý bude automaticky vygenerovaný a doručený prostredníctvom SMS správy.</p>
      <h4>7. Podmienky likvidácie údajov v mobilnej aplikácii NTS SR – Elektronická karta darcu</h4>
      <p>Osobné údaje spracovávané v mobilnej aplikácii NTS SR – Elektronická karta darcu budú likvidované na základe splnenia ktorejkoľvek z nasledujúcich podmienok:</p>
      <ol type="a">
        <li><p>ústna žiadosť dotknutej osoby podaná osobne na evidencií darcov krvi ktoréhokoľvek pracoviska NTS SR,</p></li>
        <li><p>doručením písomnej námietky dotknutej osoby proti spracúvaniu osobných údajov prevádzkovateľovi, ktorá umožňuje bez akýchkoľvek pochybností dotknutú osobu identifikovať; vzor tejto námietky sa nachádza na webovej stránke www.ntssr.sk, v časti Zásady ochrany osobných údajov,</p></li>
        <li><p>automaticky uplynutím 5 rokov od posledného darovania krvi.</p></li>
      </ol>
      <h4>8. Zoznam sprostredkovateľov, tretích strán a príjemcov, ktorým NTS SR poskytuje a sprístupňuje osobné údaje dotknutých osôb</h4>
      <p>NTS SR poskytuje a sprístupňuje osobné údaje dotknutej osoby sprostredkovateľovi – tretej strane a príjemcovi, ktorou je ako dodávateľ mobilnej aplikácie NTS SR – Elektronická karta darcu spoločnosť ICZ Slovakia a. s, so sídlom: Soblahovská 2050, 911 01 Trenčín. Poverenie sprostredkovateľa sa vykonáva na základe písomnej zmluvy uzatvorenej medzi prevádzkovateľom a sprostredkovateľom. Sprostredkovateľ spracúva osobné údaje v rozsahu a za podmienok dohodnutých s prevádzkovateľom v písomnej zmluve.</p>
      <h4>9. Zverejnenie osobných údajov</h4>
      <p>NTS SR Vaše osobné údaje nezverejňuje.</p>
      <h4>10. Cezhraničný prenos osobných údajov dotknutej osoby</h4>
      <p>Vaše osobné údaje sa prenášajú výhradne na zariadenie, ktoré bolo v mobilnej aplikácii NTS SR – Elektronická karta darcu Vami registrované. Vaše osobné údaje môžu byť predmetom prenosu osobných údajov tak v rámci štátov celej Európskej únie, ako aj v rámci tretích krajín, ktoré nie sú členským štátom Európskej únie alebo zmluvnou stranou Dohody o Európskom hospodárskom priestore, vrátane krajín, ktoré nezaručujú primeranú úroveň ochrany osobných údajov, najmä USA, Kanada a India v závislosti od polohy Vášho registrovaného zariadenia.</p>
      <h4>11. Profilovanie a automatizované rozhodovanie pri spracúvaní osobných údajov</h4>
      <p>Vaše osobné údaje nie sú používané na profilovanie alebo inú formu automatického rozhodovania.</p>
      <h4>12. Práva dotknutej osoby</h4>
      <p>Máte právo kedykoľvek sa obrátiť na NTS SR a na osobu, ktorá u nás zodpovedná za ochranu osobných údajov a požadovať informácie a prístup k spracúvaniu osobných údajov. Ďalej máte právo na výmaz, právo na opravu nepresných alebo neúplných osobných údajov, právo na obmedzenie spracovávania, uplatnenie námietky proti profilovaniu a automatizovanému rozhodovaniu a na prenositeľnosť údajov. Práva môžete uplatniť na nižšie uvedených kontaktoch NTS SR. S prípadnou sťažnosťou sa môžete obrátiť aj na Úrad na ochranu osobných údajov.</p>
      <p><b>NTS SR má zodpovednú osobu za ochranu osobných údajov, ktorá dohliada na riadne spracúvanie osobných údajov a na ktorú sa môžete kedykoľvek obrátiť, a to písomne na adrese Ďumbierska 3/L, 831 01 Bratislava, Slovenská republika, alebo elektronicky na adrese osobne.udaje@ntssr.sk.</b></p>
      <div class="fixed-bottom-button">
        <input type="submit" class="submit btn btn-primary agree-button" value="Súhlasím" @click="moveStep(true)"/>
      </div>
    </div>

    <div class="row" v-if="step === 2">
      <form @submit="submitSmsCode">
        <input type="text" id="evc" class="evc" maxlength="10" minlength="10" required="required" autocomplete="none" placeholder="EVČ *" v-model="evc" />
        <span class="form-error" v-if="requestSmsCodeError !== false">{{requestSmsCodeError}}</span>
        <input type="submit" class="submit btn btn-primary" value="Získať aktivačný kód" @click="submitSmsCode"/>
        <label class="evc-label" for="evc">* 10 miestne evidenčné číslo darcu, ktoré nájdete na preukaze darcu krvi pod čiarovým kódom alebo Vám ho poskytnú na evidencii darcov krvi NTS SR.</label>
      </form>
      <input type="submit" class="submit btn btn-primary toSmsCode-button" value="Už mám aktivačný kód" @click="moveStep(true)"/>
    </div>
    <div class="row" v-if="step === 3">
      <div class="col-12">
        <form @submit="submitActivation">
          <input type="text" id="activatePIN" class="activatePIN" maxlength="5" minlength="4" required="required" autocomplete="none" placeholder="SMS kód" v-model="activatePIN" />
          <input type="text" id="pin" class="pin" maxlength="4" minlength="4" required="required" autocomplete="none" placeholder="PIN (4 znaky)" v-model="pin" />
          <input type="text" id="confirmPin" class="confirmPin" maxlength="4" minlength="4" required="required" autocomplete="none" placeholder="PIN (zopakovať)" v-model="confirmPin" />
          <span class="form-error" v-if="activationError !== false">{{activationError}}</span>
          <input type="submit" class="submit btn btn-primary" value="Aktivovať" @click="submitActivation"/>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import Header from '@/components/Header'

export default {
  name: 'Activation',
  components: {
    Header
  },
  data () {
    return {
      activatePIN: '',
      evc: localStorage.getItem('evc') || '',
      pin: '', // TODO: 4 Input PIN
      confirmPin: '', // TODO: 4 Input PIN
      step: 0,
      requestSmsCodeError: false,
      activationError: false,
      routerLink: [0, 2]
    }
  },
  computed: {
    ...mapState([
      'smsCodeRequested'
    ])
  },
  watch: {
    '$store.state.smsCodeRequested': function () {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions([
      'requestSmsCode',
      'setSmsCodeState',
      'activateApp'
    ]),
    submitSmsCode (e) {
      e.preventDefault()
      if (this.evc !== '' && this.evc.length === 10 && /^[0-9]+$/.test(this.evc)) {
        localStorage.setItem('evc', this.evc)
        this.requestSmsCodeError = false
        this.requestSmsCode({ evc: this.evc })
      } else {
        this.requestSmsCodeError = 'Zadajte EVČ v správnom tvare'
      }
    },
    setSmsCode (state) {
      this.setSmsCodeState({ smsState: state })
    },
    moveStep (up) {
      this.step += (up === true ? 1 : -1)
    },
    submitActivation (e) {
      e.preventDefault()
      const activationCodeValidation = this.activatePIN !== '' && this.activatePIN.length >= 4 && /^[0-9]+$/.test(this.activatePIN)
      const pinCodeValidation = this.pin !== '' && this.pin.length === 4 && /^[0-9]+$/.test(this.pin)
      if (activationCodeValidation && pinCodeValidation && this.pin === this.confirmPin) {
        this.activationError = false
        this.activateApp({ activatePIN: this.activatePIN, evc: this.evc, pin: this.pin })
      } else {
        if (!activationCodeValidation) {
          this.activationError = 'Zadaný SMS kód má nesprávny tvar'
        } else if (!pinCodeValidation) {
          this.activationError = 'Zadaný PIN má nesprávny tvar'
        } else if (this.pin !== this.confirmPin) {
          this.activationError = 'Pin kódy sa nezhodujú'
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";
  .fixed-bottom-button {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px calc(.5 * var(--bs-gutter-x)) 50px calc(.5 * var(--bs-gutter-x));
    margin: 0 calc(-.5 * var(--bs-gutter-x));
    background-color: var(--background-color);
    box-shadow: 0 0 15px 10px var(--background-color);

    .submit {
      width: 100%;
      text-align: center;
      // margin: 15px 0 30px 0;
    }
  }

  #activation {
    .evc-label {
      font-size: rem(14);
      filter: brightness(60%);
      margin: 15px 0;
    }
    .toSmsCode-button {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    h3 {
      margin-top: 30px;
      font-weight: bold;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  #how-to,
  #agreement {
    text-align: left;
  }
</style>
